export enum ErrorType {
  download = 'download',
  launchElectron = 'launchElectron',
  startedElectron = 'startedElectron',
  startAnotherVisit = 'startAnotherVisit',
  redirection = 'redirection',
  token = 'token',
}

export function getErrorQueryParams(
  errorType: ErrorType,
  errorMessage?: string,
  errorAdditionalContext?: Record<string, any>,
): Record<string, any> {
  const errMessage = btoa(errorMessage);
  let errAdditionalContext: string;
  if (errorAdditionalContext) {
    errorAdditionalContext = cleanAdditionalContext(errorAdditionalContext);
    errAdditionalContext = btoa(JSON.stringify(errorAdditionalContext));
  }
  return {
    queryParams: {
      type: errorType,
      context: errMessage,
      additionalContext: errAdditionalContext,
    },
  };
}

function cleanAdditionalContext(errorAdditionalContext: Record<string, any>): Record<string, any> {
  const tokenData = errorAdditionalContext.route?.resolvedToken;
  if (tokenData) {
    errorAdditionalContext.route.resolvedToken = 'defined';
  }
  return errorAdditionalContext;
}
