import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PreloadOptions, PreloadService } from './preloading-options';

@Injectable({ providedIn: 'root', deps: [PreloadService] })
export class PreloadStrategy implements PreloadingStrategy {
  private preloadOnDemand$: Observable<PreloadOptions>;

  constructor(private preloadOnDemandService: PreloadService) {
    this.preloadOnDemand$ = this.preloadOnDemandService.preloadState;
  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return this.preloadOnDemand$.pipe(
      mergeMap((preloadOptions) => {
        const shouldPreload = this.preloadCheck(route, preloadOptions);
        return shouldPreload ? load() : EMPTY;
      }),
    );
  }

  private preloadCheck(route: Route, preloadOptions: PreloadOptions) {
    return route.path && route.path.includes(preloadOptions.routePath) && preloadOptions;
  }
}
