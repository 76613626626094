import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const sessionId = sessionStorage.getItem('aw-session-id');

    let headers = request.headers.set('Content-type', 'application/json');
    if (sessionId) {
      headers = headers.set('X-Client-Session-Id', sessionId);
    }

    const modifiedRequest = request.clone({
      headers,
    });

    return next.handle(modifiedRequest);
  }
}
