import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import merge from 'lodash.merge';
import { AppConfig } from '@app/shared/model/app-config.model';
import { getLocalStorageItem } from '@app/utilities/local-storage-utils';
import { of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
export const CarepointListServiceUrlToken = new InjectionToken('carepoint_list_service_url_token');
export const EnvUrlNameToken = new InjectionToken('env_url_name_token');

type AwFeatureFlags = Record<string, { [key: string]: boolean }>;

@Injectable({
  providedIn: 'root',
})
export class EnvConfigService {
  private settings: AppConfig;
  private settingsCache: Promise<AppConfig>;
  private readonly settingsUrl = 'assets/config/app-config.json';
  private _adsFeatureFlags: AwFeatureFlags;

  constructor(private http: HttpClient) {}

  init(): Promise<AppConfig> {
    if (this.settingsCache) {
      return this.settingsCache;
    }
    this.settingsCache = this.http
      .get<AppConfig>(this.settingsUrl)
      .toPromise()
      .then((data) => (this.settings = this.getMergedSettingsWithLocalStorageData(data)));
    return this.settingsCache;
  }

  getSettings(): AppConfig {
    return this.settings;
  }

  get adsFeatureFlags(): AwFeatureFlags {
    return this._adsFeatureFlags || {};
  }

  loadAdsFeatureFlags(tenant): Promise<AwFeatureFlags> {
    const mix = getLocalStorageItem('aw-local-ads-feature-flags');
    const { publicConfigUrl } = this.getSettings();
    const payload = {
      root: 'ads-feature-flags',
      layers: [
        'ads-feature-flags',
        `${tenant}/ads-feature-flags`,
        `${tenant}/aehr-client/ads-feature-flags`,
      ],
      mix,
    };
    return this.http
      .post<any>(publicConfigUrl, payload)
      .pipe(
        map((response) => response || null),
        tap((adsFeatureflags) => (this._adsFeatureFlags = adsFeatureflags)),
        catchError((err: any) => {
          console.error(
            '[PublicConfigHttpService] Unable to get public-config data with payload',
            payload,
            'Error',
            err,
          );
          return of([]);
        }),
      )
      .toPromise();
  }

  private getMergedSettingsWithLocalStorageData(settings: AppConfig): AppConfig {
    const localSettings = getLocalStorageItem('aw-local-aehr-client');
    if (localSettings) {
      merge(settings, localSettings);
    }
    return settings;
  }
}
