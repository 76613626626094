import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { EnvConfigService } from '@app/shared/service/env-config.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

interface State {
  electronDownloadUrl: string;
  electronUrl: string;
  visitUrl: string;
}

/**
 * Electron State
 *
 * This resolver consults localStorage to see if a user
 * has previously attempted to load route with electron
 * configuration information.
 *
 * Regardless of whether that information has been stored
 * previously, this resolver will store any existing route
 * parameters for this state into localStorage.
 */
@Injectable()
export class ElectronState implements Resolve<State> {
  public localStorageKey = 'electronState';
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private envConfigService: EnvConfigService,
  ) {}

  getElectronDownloadUrl(): string {
    const settings = this.envConfigService.getSettings();
    return `https://${settings.electronDownloadHost}/WebRTC/${settings.electronVersion}/TelehealthVideoSetup.exe`;
  }

  getElectronLaunchLink(encodedLandingPageUrl: string): string {
    const settings = this.envConfigService.getSettings();
    return `aw-rtc-video-${settings.electronVersion}://startVideo?url=${encodedLandingPageUrl}`;
  }

  public resolve(): Observable<State> {
    const storedState = localStorage.getItem(this.localStorageKey);
    const state = this.router.getCurrentNavigation().extras.state;
    console.log(state);
    if (state && state.electronUrl && state.electronDownloadUrl) {
      const electronState = {
        electronUrl: state.electronUrl,
        electronDownloadUrl: state.electronDownloadUrl,
        visitUrl: state.visitUrl,
      };
      localStorage.setItem(this.localStorageKey, JSON.stringify(electronState));
      return of({ ...electronState, sameRouteInfo: state.sameRouteInfo });
    }

    // if there is a visitUrl and no electron information, let's fetch the electron information from
    // the service and append it to the results.
    if (state && !!state.visitUrl && state && !state.electronDownloadUrl && !state.electronUrl) {
      return of({
        electronDownloadUrl: this.getElectronDownloadUrl(),
        electronUrl: this.getElectronLaunchLink(state.visitUrl),
      }).pipe(
        map((x: any) => {
          const toPersist = {
            visitUrl: state.visitUrl,
            electronDownloadUrl: x.electronDownloadUrl,
            electronUrl: x.electronUrl,
          };
          localStorage.setItem(this.localStorageKey, JSON.stringify(toPersist));
          return { ...toPersist, sameRouteInfo: state.sameRouteInfo };
        }),
      );
    }

    if (storedState !== undefined && storedState !== 'undefined' && !!storedState) {
      return of(JSON.parse(storedState));
    }

    return of({
      electronDownloadUrl: '',
      electronUrl: '',
      visitUrl: '',
    });
  }
}
