export const DEFAULT_TENANT_ID = 'AMWL';
export const SIX_HOURS = 6 * 60 * 60 * 1000;
export const TOP_LEVEL_APPLICATION = 'AEHR Client';
export const AEHR_CLIENT_NAME = 'aehr-client';
export const EMBEDDED_APP_NAME = 'embedded-app';
export const VISIT_ENDED_FIELD = 'visit-ended';

export const LG_ESITTER_CLIENT = 'LG';
export const SOLABORATE_ESITTER_CLIENT = 'SOLABORATE';
export const FHIR_ON_DEMAND_PROPERTY = 'amwell-on-demand';
export const FHIR_PRINCIPAL_PRACTITIONER_CODE = 'PPRF';
export const FHIR_PRACTITIONER_REFERENCE = 'Practitioner';
export const FHIR_PRACTITIONER_REFERENCE_PREFIX = 'Practitioner/';
export const FHIR_PRACTITIONER_ROLE_REFERENCE_PREFIX = 'PractitionerRole/';
/**
 * Default wait time to pass from the cancel confirmation to end-visit screen - 6 seconds.
 */
export const CANCEL_VISIT_DEFAULT_WAIT_TIME = 6000;
/**
 * Default wait time to request the encounter again when validating the practitioner - 1 minute
 */
export const REQUEST_ENCOUNTER_AGAIN_DEFAULT_WAIT_TIME = 60000;
