<aw-progress-bar
  class="global-progress-bar"
  *ngIf="globalProgressBarIsVisible$ | async"
  @fadeInOut
  data-aw-id="global-progress-bar"
  type="indeterminate"
></aw-progress-bar>
<ng-container *ngIf="$('themeName') | async as themeName">
  <div *ngIf="showThemeSwitcher" [class]="'theme-' + themeName">
    <aw-button
      [ngStyle]="{
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 100
      }"
      [aw-aria-label]="(themeName === 'theme-dark' ? 'dark_mode' : 'light_mode') | i18next"
      (onClick)="toggleTheme()"
      styleClasses="btn-flat btn-large only-ic m-t2 neutral1-text"
    >
      <span class="material-icons-outlined m-r1" aria-hidden="true">
        {{ themeName === 'dark' ? 'dark_mode' : 'light_mode' }}
      </span>
    </aw-button>
  </div>
  <div class="brand-background theme-{{ themeName }}" *ngIf="branding">
    <img
      class="brand-logo"
      [src]="$('logo.url') | async"
      [alt]="$('logoAltText') | async"
      data-aw-id="brand-logo"
    />
  </div>
</ng-container>
<div
  class="aw-ds no-bg h100"
  [ngClass]="{ 'centered-card-wrapper': layoutType === 'standAlone' }"
  [ngStyle]="{ justifyContent: 'center' }"
>
  <ng-container [ngSwitch]="true">
    <!-- STANDALONE OR FULLSCREEN -->
    <ng-container *ngSwitchCase="fullScreen || layoutType === 'standAlone'">
      <router-outlet></router-outlet>
    </ng-container>
    <!-- RESPONSIVE -->
    <ng-container *ngSwitchCase="layoutType === 'responsive'">
      <aw-responsive-layout
        class="no-header"
        [mobileBackgroundUrl]="$('backgroundImageMobile.url') | async"
        [desktopBackgroundUrl]="$('backgroundImageDesktop.url') | async"
        type="Type2"
        mode="D"
      >
        <router-outlet *awLayoutContent="'content'"></router-outlet>
        <ng-container *ngIf="footer">
          <aw-copyright *awLayoutContent="'footer'" styleClasses="small m-b0">
            <div class="m-b2" *ngIf="!configuration.hideAmwellElements">
              © {{ currentYear }} American Well Corporation. All rights reserved.
            </div>
            <a [title]="'open_in_new_tab' | i18next" href="{{ helpUrl }}" target="_blank"
              >{{ 'need_help' | i18next
              }}<span class="visually-hidden-focusable">({{ 'open_in_new_tab' | i18next }})</span
              ><span class="material-icons new-tab-icon" aria-hidden="true">open_in_new</span></a
            >
          </aw-copyright>
        </ng-container>
        <ng-container *ngIf="!configuration.hideAmwellElements">
          <aw-footer
            *awLayoutContent="'globalFooter'"
            [class]="footerClass"
            [content]="'footer_content' | i18next : { year: currentYear }"
            [links]="[
              {
                name: 'terms_of_use' | i18next,
                href: 'https://business.amwell.com/terms-of-use/',
                isTargetBlank: true
              },
              {
                name: 'privacy_policy' | i18next,
                href: 'https://business.amwell.com/privacy-policy/',
                isTargetBlank: true
              }
            ]"
          >
          </aw-footer>
        </ng-container>
      </aw-responsive-layout>
    </ng-container>
    <!-- TODO LEGACY BY DEFAULT -->
    <ng-container *ngSwitchDefault>
      <div class="centered-card-wrapper" *ngIf="!fullScreen">
        <div class="centered-card">
          <aw-panel [hidden]="shouldHideBox" styleClasses="neutral1">
            <div class="panel-body" [ngClass]="classNames">
              <router-outlet></router-outlet>
            </div>
            <div class="panel-footer neutral7-text fw-light fz-small" *ngIf="footer">
              <div class="m-b2" *ngIf="!configuration.hideAmwellElements">
                {{ 'launch_visit_copyright' | i18next : { year: currentYear } }}
              </div>
              <a [title]="'open_in_new_tab' | i18next" href="{{ helpUrl }}" target="_blank"
                >{{ 'need_help' | i18next
                }}<span class="visually-hidden-focusable">({{ 'open_in_new_tab' | i18next }})</span
                ><span class="material-icons new-tab-icon" aria-hidden="true">open_in_new</span></a
              >
            </div>
          </aw-panel>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="feedback-container" *ngIf="feedback">
    <app-feedback></app-feedback>
  </div>
</div>
<div data-aw-id="vcc"></div>
