export enum RouteName {
  EndCall = 'end-call',
  Rating = 'rating',
  RatingDislike = 'rating-dislike',
  TermsOfUse = 'tou',
  DownloadApp = 'download-app',
  VisitStarted = 'visit-started',
  LaunchApp = 'launch-app',
  VisitEnded = 'visit-ended',
  ThankYou = 'thank-you',
  ExpiredLink = 'expired-link',
  UnauthorizedVisit = 'unauthorized-visit',
  UnsupportedBrowser = 'unsupported-browser',
  Start = 'start',
  Error = 'error',
  CallStart = 'call/start',
  TechCheck = 'tech-check',
  CallIntake = 'call/intake',
  GuestIntake = 'guest/intake',
  CallConference = 'call/conference',
  PatientIntake = 'patient/intake',
  PatientSpinner = 'patient/spinner',
  HomeTv = 'hometv',
  ProviderLanding = 'provider-landing',
  Support = 'support',
  PractitionerSpinner = 'practitioner/spinner',
  CarepointCall = 'carepoint-call',
  Auth = 'auth',
  AuthLogout = 'auth/logout',
  Wildcard = '**',
}
