/**
 * Performs various DOM related functionality
 */
export class DomUtils {
  /**
   * Opens an external Url without leaving the current component.
   *
   * @param externalUrl The external Url to open
   * @param selector A valid component selector's tag name. Default is 'app-root'
   * @returns undefined.
   */
  public static openUrl(externalUrl: string, selector: string = 'app-root'): void {
    const parentNode = document.querySelector(selector);
    if (parentNode) {
      const iFrame: HTMLIFrameElement = document.createElement('iframe');
      iFrame.src = externalUrl;
      iFrame.style.display = 'none';
      parentNode.appendChild(iFrame);
    }
  }
}
