import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { DEFAULT_TENANT_ID, TOP_LEVEL_APPLICATION } from '@app/shared/constants/common';
import { StorageKeys } from '@app/shared/constants/web-storage';
import { QueryParamKeys } from '@app/shared/constants/query-param-keys';
import { AppConfig } from '@app/shared/model/app-config.model';
import { TlaConfig } from '@app/shared/model/tla-config.model';
import { WINDOW_TOKEN } from '@app/shared/service/window-token';
import { LOCATION_TOKEN } from '@app/shared/service/location-token';
import { getCacheTime, getParsedLocalStorageItemWithTtl } from '@app/utilities/local-storage-utils';

@Injectable({ providedIn: 'root' })
export class DomainService {
  private brandingUrl: string;
  private tlaUrl: string;

  constructor(
    @Inject(WINDOW_TOKEN) private readonly window: Window,
    @Inject(LOCATION_TOKEN) private readonly location: Location,
    private readonly http: HttpClient,
  ) {}

  getTlaConfig(appConfig: AppConfig): Observable<TlaConfig> {
    this.brandingUrl = `${appConfig.brandingRoot}/config/domains.json`;
    this.tlaUrl = `${appConfig.invitationServiceUrl}/top-level-application-service/api/v1/top-level-application`;
    const cachedConfig = getParsedLocalStorageItemWithTtl<TlaConfig>(
      this.window.localStorage.getItem(StorageKeys.tlaConfig),
    );

    if (cachedConfig) {
      return of(cachedConfig);
    }

    return this.getTenantId().pipe(
      switchMap((tenantId) => {
        const body = {
          tenant: tenantId,
          topLevelApplication: TOP_LEVEL_APPLICATION,
        };

        return this.fetchTlaConfig(body);
      }),
      tap((config) => {
        if (config) {
          this.window.localStorage.setItem(
            StorageKeys.tlaConfig,
            JSON.stringify({ val: config, ttl: getCacheTime() }),
          );
        }
      }),
    );
  }

  getTenantId(): Observable<string> {
    const queryParams = new URLSearchParams(decodeURIComponent(this.location.search));
    const queryTenantId = queryParams.get(QueryParamKeys.tenantId);

    const localTenantId: string | null = this.window.localStorage.getItem(StorageKeys.TenantId);
    if (localTenantId && (!queryTenantId || localTenantId === queryTenantId)) {
      return of(localTenantId);
    }

    return this.fetchDomains().pipe(
      map((domains) => {
        const tenantId =
          queryTenantId && domains[queryTenantId.toLowerCase()]
            ? queryTenantId.toUpperCase()
            : DEFAULT_TENANT_ID;

        this.window.localStorage.setItem(StorageKeys.TenantId, tenantId);

        return tenantId;
      }),
    );
  }

  fetchDomains(): Observable<{ [key: string]: string }> {
    return this.http.get<{ [key: string]: string }>(this.brandingUrl);
  }

  fetchTlaConfig(body): Observable<TlaConfig> {
    return this.http.post<TlaConfig>(this.tlaUrl, body);
  }
}
