import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvConfigService } from '@app/shared/service/env-config.service';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent implements OnInit {
  private get returnTo(): string {
    return `${window.location.origin}/auth`;
  }

  constructor(
    private authService: AuthService,
    private envConfigService: EnvConfigService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.envConfigService.getSettings().auth?.enabled) {
      this.authService.logout({ returnTo: this.returnTo });
    } else {
      this.router.navigate(['/']);
    }
  }
}
