import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { WINDOW_TOKEN } from '../service/window-token';
@Injectable({
  providedIn: 'root',
})
export class StartVisitGuard implements CanActivate {
  constructor(private router: Router, @Inject(WINDOW_TOKEN) public window: Window) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const browserChecksPassed = route.queryParams.browserChecksPassed;
    if (browserChecksPassed) {
      return of(true);
    }
    const sameRouteInfo = {
      routeConfig: {
        path: route?.routeConfig?.path,
      },
      queryParams: route?.queryParams,
      browserChecksPassed: true,
    };
    this.router.navigate(['/download-app'], {
      queryParams: sameRouteInfo.queryParams,
      state: { visitUrl: this.window.location.href, sameRouteInfo },
    });
    return of(false);
  }
}
