import { DomUtils } from '@amwellnow/browser/dom.utils';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppInitService } from '@app/shared/service/app-init.service';
import { take, withLatestFrom } from 'rxjs/operators';
import { ApmService } from '@app/apm-module';
import { RouteName } from '@app/shared/model/route-name.enum';
import { ErrorType, getErrorQueryParams } from '@app/shared/components/error-modal/error-utils';

@Component({
  selector: 'app-host-prep',
  templateUrl: './host-prep.component.html',
  styleUrls: ['./host-prep.component.less'],
})
export class ElectronPrepComponent implements OnInit {
  public state$;
  public componentSelector: string;
  public providerEmail: string;
  public token: string;

  constructor(
    private apm: ApmService,
    public configuration: AppInitService,
    private hostElement: ElementRef,
    public activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.configuration.showAwPanelOnMainPage();
    this.activatedRoute.data
      .pipe(withLatestFrom(this.activatedRoute.queryParams), take(1))
      .subscribe(([routeData, queryParams]) => {
        if (
          !routeData ||
          !routeData.state ||
          !routeData.state.electronUrl ||
          !routeData.state.electronDownloadUrl
        ) {
          const message = 'Electron state information not found. Redirecting to the error page';
          console.error(message);
          this.apm.apmBase.captureError(new Error(message));
          this.router.navigate(
            [RouteName.Error],
            getErrorQueryParams(ErrorType.download, message, { route: routeData }),
          );
        } else {
          this.providerEmail = queryParams.pe || undefined;
          this.state$ = routeData.state;
          this.token = routeData.token || routeData?.resolvedToken?.token;
          if (this.token) {
            sessionStorage.setItem('aw-bearer-token', this.token);
          }
        }
      });
    this.componentSelector = this.hostElement.nativeElement.tagName.toLowerCase();
  }

  downloadClicked($event) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    $event.preventDefault ? $event.preventDefault() : ($event.returnValue = false);
    localStorage.setItem('electronInstalled', 'true');
    // eslint-disable-next-line no-console
    console.info('Sending the electron installer to the user');
    DomUtils.openUrl(this.state$.electronDownloadUrl, this.componentSelector);
  }

  launchClicked() {
    DomUtils.openUrl(this.state$.electronUrl, this.componentSelector);
    setTimeout(() => {
      this.router.navigate(['/visit-started'], { state: this.state$ });
    }, 4000);
  }
}
