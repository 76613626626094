import { Inject, Injectable, NgZone, ErrorHandler, Optional } from '@angular/core';
import { AgentConfigOptions, Span } from '@elastic/apm-rum';
import { testStorage } from '@aw/video-util';
import { InjectionToken, NgModule } from '@angular/core';
import {
  RouterModule,
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { apm, ApmBase, Transaction } from '@elastic/apm-rum';
import { detect } from 'detect-browser';
import { filter, map, tap } from 'rxjs/operators';
import { merge } from 'rxjs';

export const APM = new InjectionToken<ApmBase>('APM Base Client');
export type OutcomeTransaction = Transaction & { outcome?: 'success' | 'failure' };

function keys(store) {
  const numKeys = store.length;
  const ks = [];
  for (let i = 0; i < numKeys; i++) {
    ks.push(store.key(i));
  }
  return ks;
}

@Injectable()
export class ApmErrorHandler extends ErrorHandler {
  constructor(@Inject(APM) public apmBase: ApmBase) {
    super();
  }

  handleError(error) {
    this.apmBase.captureError(error.originalError || error);
    super.handleError(error);
  }
}

function safeParse(stuff) {
  try {
    return JSON.parse(stuff);
  } catch (e) {
    return {};
  }
}

function slurp(list, object) {
  return list.reduce((obj, key) => {
    obj[key] = object[key];
    return obj;
  }, {});
}

export function addMetadataHelper(transaction: any, isEmbeddedApp?: boolean): any {
  const maybeTokenPayload = sessionStorage.getItem('aw-token-payload') || '{}';
  const parsedTokenPayload = safeParse(maybeTokenPayload);
  const awSessionId = sessionStorage.getItem('aw-session-id');
  const localStorageKeys = keys(localStorage);
  const sessionStorageKeys = keys(sessionStorage);

  let labels: any;

  if (awSessionId) {
    labels = {
      ...labels,
      aw_session_id: awSessionId,
    };
    transaction.addLabels({ aw_session_id: awSessionId });
  }

  if (isEmbeddedApp) {
    labels = {
      ...labels,
      is_embedded_app: isEmbeddedApp,
    };
    transaction.addLabels({ is_embedded_app: isEmbeddedApp });
  }

  const stuffICareAbout = slurp(
    ['tenantKey', 'roomSourceId', 'role', 'ehrId', 'ehrType', 'launchId'],
    parsedTokenPayload,
  );

  labels = {
    ...labels,
    ...stuffICareAbout,
    session_storage_keys: sessionStorageKeys,
    local_storage_keys: localStorageKeys,
  };
  transaction.addLabels(labels);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  for (const [_, span] of Object.entries(transaction.spans || {})) {
    (span as Span).addLabels(labels);
  }

  return {
    parsedTokenPayload,
    awSessionId,
    localStorageKeys,
    sessionStorageKeys,
  };
}

type NavigationEvent = NavigationStart | NavigationEnd | NavigationError | NavigationCancel;
interface NavigationPair {
  transaction: OutcomeTransaction;
  event: NavigationEvent;
}

const is = (...classes) =>
  filter<NavigationEvent>((m) => classes.some((klass) => m instanceof klass));

@Injectable({
  providedIn: 'root',
})
export class ApmService {
  public environment: string;
  public isEmbeddedApp: boolean;

  constructor(
    @Inject(APM) public apmBase: ApmBase,
    private readonly ngZone: NgZone,
    @Optional() public router: Router,
  ) {
    const transactions = new Map<number, OutcomeTransaction>();

    const stored = filter<NavigationEvent>((e) => !!transactions.get(e.id));
    const capture = tap((e) => this.apmBase.captureError(e.toString()));
    const withTransaction = map<NavigationEvent, NavigationPair>((event) => ({
      transaction: transactions.get(event.id),
      event,
    }));

    if (this.router?.events) {
      const setOutcome = (outcome) =>
        tap<NavigationPair>(({ transaction }) => (transaction.outcome = outcome));
      /** all Angular router start events emit here */
      const start$ = this.router.events.pipe(is(NavigationStart));

      /** all Angular router events we consider errors emit here */
      const error$ = this.router.events.pipe(
        is(NavigationError),
        stored, // keep only events we have stored in our map
        capture, // report errors to APM
        withTransaction, // bundle the event with the transaction
        setOutcome('failure'), // set transaction outcome to failure
      );

      /** all Angular router events we consider "conclusions" emit here */
      const end$ = this.router.events.pipe(
        is(NavigationCancel, NavigationEnd),
        stored, // keep only events we have stored in our map
        withTransaction, // bundle the event with the transaction
        setOutcome('success'), // set transaction outcome to success
      );

      // Trigger a page load transaction on navigation change
      start$.subscribe((event) => {
        const transaction = this.apmBase.startTransaction(
          event.url.split('?')[0],
          'page-navigation',
          {
            managed: false,
          },
        );
        addMetadataHelper(transaction, this?.isEmbeddedApp);
        transactions.set(event.id, transaction);
      });

      merge(error$, end$).subscribe(({ transaction, event }) => {
        /** immediately remove the transaction from our state map */
        transactions.delete(event.id);
        /** mark the transaction as concluded */
        transaction?.end();
      });
    }
  }

  init(config: AgentConfigOptions, isEmbeddedApp?: boolean): ApmBase {
    this.isEmbeddedApp = isEmbeddedApp;
    const apmInstance = this.ngZone.runOutsideAngular(() => {
      this.environment = config.environment;
      return this.apmBase.init(config);
    });

    const detected = detect();
    let labels: any = {
      detected_os: detected.os,
      detected_name: detected.name,
      detected_version: detected.version,
      detected_type: detected.type,
    };
    apmInstance.addLabels(labels);

    function addMetadata(transaction: any): void {
      const { parsedTokenPayload, awSessionId, localStorageKeys, sessionStorageKeys } =
        // eslint-disable-next-line no-invalid-this
        addMetadataHelper(transaction, this?.isEmbeddedApp);
      if (awSessionId) {
        /** make sure this is also appended to errors, yo */
        apmInstance.addLabels({ aw_session_id: awSessionId });
      }

      const stuffICareAbout = slurp(
        ['tenantKey', 'roomSourceId', 'encounterId', 'role', 'ehrId', 'ehrType', 'launchId'],
        parsedTokenPayload,
      );

      const { encounterId, ...otherStuff } = stuffICareAbout;

      labels = {
        ...labels,
        ...otherStuff,
        session_storage_keys: sessionStorageKeys,
        local_storage_keys: localStorageKeys,
      };
      apmInstance.setCustomContext({
        encounter_id: encounterId,
        ...otherStuff,
      });
      apmInstance.setCustomContext({ storage_enabled: testStorage() });
    }

    /** it is possible that transactions don't end well, so do this at start to be safe */
    this.apmBase.observe('transaction:start', addMetadata);
    /** it is possible not all metadata is available when a transaction starts, so do it at the end to be EXTRA SUPER SAFE */
    this.apmBase.observe('transaction:end', addMetadata);

    if (!apmInstance.isActive()) {
      return apmInstance;
    }

    /**
     * Start listening to route change once we
     * intiailize to set the correct transaction names
     */
    // this.observe()
    return apmInstance;
  }

  observe(): void {}
}

@NgModule({
  imports: [RouterModule],
  providers: [{ provide: APM, useValue: apm }],
})
export class ApmModule {}
