export interface IConfiguration {
  name: string;
  brand: string;
  imageURLs: IImageURLs;
  emails: string[];
  subdomain: string;
  title: string;
  dir: string;
  subscriptionCheck: boolean;
  conferenceVendorId: string;
  supportEmail: string;
  supportPhoneNumber: string;
  supportContactText: string;
  touLegalText: string;
  touAcknowledgementText: string;
  hideAmwellElements: boolean;
  names?: [
    {
      name: string;
    },
  ];
  requiredIntakePageControls?: REQUIRED_INTAKE_PAGE_CONTROLS[];
}

export interface IImageURLs {
  background: string;
  logo: string;
  header: string;
  favicon?: string;
}

export enum REQUIRED_INTAKE_PAGE_CONTROLS {
  PATIENT_NAME = 'PATIENT_NAME',
  PATIENT_PHONE_NUMBER = 'PATIENT_PHONE_NUMBER',
  ACCEPTED_NOPP = 'ACCEPTED_NOPP',
}
