import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApmService } from '@app/apm-module';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VISIT_ENDED_FIELD } from '@app/shared/constants/common';

const ErrorTypeTexts = {
  default: { title: 'something_went_wrong', text: 'intake_error' },
  token: { title: 'expired_link_title', text: 'intake_expired_link_2' },
  download: { title: 'something_went_wrong', text: 'error_download_text' },
  launchElectron: { title: 'something_went_wrong', text: 'error_launch_electron_text' },
  startedElectron: { title: 'something_went_wrong', text: 'error_started_electron_text' },
  startAnotherVisit: { title: 'something_went_wrong', text: 'error_started_visit_text' },
  redirection: { title: 'something_went_wrong', text: 'error_redirection_text' },
  visitEnded: { title: 'unauthorized_visit_title', text: 'intake_error' },
};

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.less'],
})
export class ErrorModalComponent {
  errorTitle: string;
  errorText: string;
  lastErrorType: string;
  errorMessage: string;
  additionalContext: string;
  sessionId: string;
  private isDestroyed$ = new Subject();

  constructor(
    private apm: ApmService,
    private route: ActivatedRoute,
    @Optional() @Inject(MAT_DIALOG_DATA) public readonly matDialogData: { message: string },
  ) {
    this.readQueryParamsData();
    this.errorTitle = ErrorTypeTexts[this.lastErrorType]?.title ?? ErrorTypeTexts.default.title;
    this.errorText = ErrorTypeTexts[this.lastErrorType]?.text ?? ErrorTypeTexts.default.text;
    this.validateSessionId();
    this.validateVisitEnded();
    this.manageApmTransaction();
  }

  private manageApmTransaction(): void {
    const transaction = this.apm.apmBase.getCurrentTransaction();
    transaction?.addLabels({ error_type: this.lastErrorType });
    transaction?.addLabels({ error_context: this.errorMessage });
    transaction?.addLabels({ error_additional_context: this.additionalContext });
    transaction?.mark('showed_error_modal');
  }

  private validateVisitEnded(): void {
    const visitEnded = sessionStorage.getItem(VISIT_ENDED_FIELD);
    if (visitEnded === 'true') {
      this.errorTitle = ErrorTypeTexts.visitEnded.title;
      this.errorText = ErrorTypeTexts.visitEnded.text;
    }
  }

  private validateSessionId(): void {
    this.sessionId = sessionStorage.getItem('aw-session-id') ?? sessionStorage.getItem('sessionId');
    if (this.sessionId === 'null' || this.sessionId?.trim() === '') {
      this.sessionId = undefined;
    }
  }

  private readQueryParamsData(): void {
    this.route.queryParams.pipe(takeUntil(this.isDestroyed$)).subscribe((params) => {
      this.lastErrorType = decodeURI(params.type || '');
      const message = decodeURI(params.context || '');
      if (message) {
        this.errorMessage = atob(message);
      }
      const addContext = decodeURI(params.additionalContext || '');
      if (addContext) {
        this.additionalContext = atob(addContext);
      }
    });
  }
}
