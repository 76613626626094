<aw-floating-dialog
  [sideOffset]="24"
  [bottomOffset]="24"
  [config]="floatingDialogConfig"
  [buttonConfig]="floatingDialogButtonConfig"
  [dismissButtonLabel]="thePlugin?.messages.close"
  position="right"
>
  <i class="material-icons" aria-hidden="true" modal-close>close</i>
  <h3 modal-header>{{ dialogHeader$ | async }}</h3>
  <div class="dialog-modal-container" #modalContainer></div>
</aw-floating-dialog>
