<div
  class="circle-graphics border s-icon primary-2-text"
  *awLayoutContent="'titleImage'"
  data-aw-id="download-icon"
  alt=""
>
  <span class="material-icons-outlined primary-2-text">file_download</span>
</div>
<ng-container *awLayoutContent="'header'">
  {{ 'set_up_video' | i18next }}
</ng-container>

<ng-container *awLayoutContent="'subheader'">
  {{ 'please_follow' | i18next }}
</ng-container>

<div class="col s10 offset-s1">
  <aw-panel styleClasses="m-b3 color-border primary-2-border">
    <div class="panel-body">
      <div class="flex flex-row">
        <aw-badge styleClasses="num small num-outline m-l0">1</aw-badge>

        <div class="flex flex-column px2 flex-1">
          <h5 class="flex" data-aw-id="step-one">
            {{ 'step_one' | i18next }}
          </h5>

          <span class="flex text-left" data-aw-id="step-one-explanation">
            {{ 'step_one_explanation' | i18next }}
          </span>
        </div>

        <aw-button
          class="flex items-center m-l-auto"
          (click)="downloadClicked($event)"
          styleClasses="btn"
          data-aw-id="downloadClicked"
          >{{ 'download' | i18next }}</aw-button
        >
      </div>
    </div>
  </aw-panel>
  <aw-panel styleClasses="m-b3 color-border primary-2-border">
    <div class="panel-body">
      <div class="flex flex-row">
        <aw-badge styleClasses="num small num-outline m-l0">2</aw-badge>

        <div class="flex flex-column px2 w100">
          <h5 class="flex" data-aw-id="step-two">
            {{ 'step_two' | i18next }}
          </h5>

          <span class="flex text-left" data-aw-id="step-two-explanation">
            {{ 'step_two_explanation' | i18next }}
          </span>
        </div>
      </div>
    </div>
  </aw-panel>
  <aw-panel styleClasses="m-b3 color-border primary-2-border">
    <div class="panel-body">
      <div class="flex flex-row">
        <aw-badge styleClasses="num small num-outline m-l0">3</aw-badge>

        <div class="flex flex-column px2 flex-1">
          <h5 class="flex" data-aw-id="step-three">
            {{ 'step_three' | i18next }}
          </h5>

          <span class="flex text-left" data-aw-id="step-three-explanation">
            {{ 'step_three_explanation_1' | i18next }}
            <br />
            {{ 'step_three_explanation_2' | i18next }}
          </span>
        </div>

        <aw-button
          class="flex items-center m-l-auto"
          (click)="launchClicked()"
          styleClasses="btn"
          data-aw-id="launchClicked"
          >{{ 'launch' | i18next }}</aw-button
        >
      </div>
    </div>
  </aw-panel>
  {{ 'download_link' | i18next | translateCut : 0 }}
  <a (click)="downloadClicked($event)" href="#">{{
    'download_link' | i18next | translateCut : 1
  }}</a>
  {{ 'download_link' | i18next | translateCut : 2 }}
</div>
