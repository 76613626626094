import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export class PreloadOptions {
  constructor(public routePath: string) {}
}

@Injectable({ providedIn: 'root' })
export class PreloadService {
  private preloadSubject = new Subject<PreloadOptions>();
  // eslint-disable-next-line @typescript-eslint/member-ordering,no-invalid-this
  preloadState = this.preloadSubject.asObservable();

  startPreload(routePath: string) {
    const preloadModule = new PreloadOptions(routePath);
    this.preloadSubject.next(preloadModule);
  }
}
