<div class="error-icon-container m-x-auto" *awLayoutContent="'titleImage'" data-aw-id="error-icon">
  <img class="heading-logo" data-aw-id="icon" src="assets/common/error-circle.svg" alt="" />
</div>
<ng-container *awLayoutContent="'header'" data-aw-id="error-title">
  {{ errorTitle | i18next }}
</ng-container>
<div data-aw-id="error-msg">
  {{ matDialogData?.message || (errorText | i18next) }}
  <ng-container *ngIf="sessionId !== undefined">
    <br />{{ 'error_session_id' | i18next }} {{ sessionId }}
  </ng-container>
  <ng-container *ngIf="additionalContext !== undefined">
    <br />{{ 'error_additional_context' | i18next }}<br />
    {{ additionalContext }}
  </ng-container>
</div>
