import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateCut } from '@app/shared/pipes/translate-cut/translate-cut.pipe';

@NgModule({
  declarations: [TranslateCut],
  exports: [TranslateCut],
  imports: [CommonModule],
})
export class TranslateCutModule {}
