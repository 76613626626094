import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenContext } from '@aw/vms-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../model/app-config.model';
import { EnvConfigService } from './env-config.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  settings: AppConfig;

  get token(): string {
    return sessionStorage.getItem('aw-bearer-token');
  }

  get tenantId(): string {
    const tokenContext = TokenContext.parse(this.token);
    return tokenContext?.payload?.tenantKey;
  }

  constructor(private envConfigService: EnvConfigService, private http: HttpClient) {
    this.settings = this.envConfigService.getSettings();
  }

  getDevice(deviceId: string): Observable<any> {
    const headers = this.createHeaders();
    const url = this.settings.carepointDeviceServiceUrl;
    return this.http
      .get<{ data: any[] }>(`${url}/api/v1/devices`, {
        headers,
        params: {
          tenantId: this.tenantId,
        },
      })
      .pipe(map((res) => res?.data?.find((device: any) => device.deviceId === deviceId)));
  }

  private createHeaders(): HttpHeaders {
    let headers = new HttpHeaders();

    headers = headers.append('Authorization', `Bearer ${this.token}`);
    headers = headers.append('Accept', `application/json`);

    if (sessionStorage.getItem('sessionId')) {
      headers = headers.append('X-Client-Session-Id', sessionStorage.getItem('sessionId'));
    }

    return headers;
  }
}
