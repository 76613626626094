import { LOCALE_ID } from '@angular/core';
import { AppInitService } from '@app/shared/service/app-init.service';
import { environment } from '@environments/environment';
import {
  defaultInterpolationFormat,
  I18NextLoadResult,
  I18NextModule,
  I18NEXT_SERVICE,
  ITranslationService,
} from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { getTranslations } from '@aw/i18n';
import resourcesToBackend from 'i18next-resources-to-backend';

const i18nextOptions = {
  fallbackLng: 'en',
  debug: environment.production,
  returnEmptyString: false,
  lng: navigator.language.split('-')[0],
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
    escapeValue: false,
  },
};

export function appInit(
  appLoadService: AppInitService,
  i18next: ITranslationService,
): () => Promise<I18NextLoadResult> {
  return () =>
    appLoadService.initPromise.then(() => {
      const promise: Promise<I18NextLoadResult> = i18next
        .use(HttpApi)
        // Fallback language (EN) translations
        // Will be used unless the browser language translations are not provided or not full
        .use(
          resourcesToBackend(() =>
            getTranslations(['aehr-client-translation', 'aehr-client-error'], 'en'),
          ),
        )
        // Browser language translations
        .use(
          resourcesToBackend((language: string) =>
            getTranslations(['aehr-client-translation', 'aehr-client-error'], language),
          ),
        )
        .use<any>(LanguageDetector)
        .init(i18nextOptions);
      return promise;
    });
}

export function localeIdFactory(i18next: ITranslationService): string {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];

export const configureI18Next = appInit;
